import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { BrowserRouter, Route, Link } from "react-router-dom";

// import {ReactComponent as logo} from "../svg_css_fm.svg";

const navigation = [
  { name: 'Accueil', href: "/" },
  { name: 'Blog', href: "#"},
  { name: 'Univers', href: "#"},
  { name: 'Tutoriels', href: "#"},
]

export default function Example() {
  return (
    <div className="relative overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
          <Popover>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                  <div className="flex w-full items-center justify-between md:w-auto">
                    <a href="#">
                      <span className="sr-only">Your Company</span>
                      {/* <img
                        alt="Your Company"
                        className="h-8 w-auto sm:h-10"
                        src={Logo}
                      /> */}
                        <svg id="e7ZrTe0tQIo1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 640 480" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                        <defs>
                          <filter id="e7ZrTe0tQIo4-filter" x="-150%" width="400%" y="-150%" height="400%">
                            <feComponentTransfer id="e7ZrTe0tQIo4-filter-opacity-0" result="result">
                              <feFuncA id="e7ZrTe0tQIo4-filter-opacity-0-A" type="table" tableValues="0 1"/>
                            </feComponentTransfer>
                          </filter>
                          <filter id="e7ZrTe0tQIo8-filter" x="-150%" width="400%" y="-150%" height="400%">
                            <feComponentTransfer id="e7ZrTe0tQIo8-filter-opacity-0" result="result">
                              <feFuncA id="e7ZrTe0tQIo8-filter-opacity-0-A" type="table" tableValues="0 1"/>
                            </feComponentTransfer>
                          </filter>
                          <filter id="e7ZrTe0tQIo10-filter" x="-150%" width="400%" y="-150%" height="400%">
                            <feComponentTransfer id="e7ZrTe0tQIo10-filter-opacity-0" result="result">
                              <feFuncA id="e7ZrTe0tQIo10-filter-opacity-0-A" type="table" tableValues="0 1"/>
                            </feComponentTransfer>
                          </filter>
                        </defs>
                        <g transform="translate(.000001 0.000001)">
                          <rect width="410" height="360" rx="0" ry="0" transform="translate(115 60.052965)" fill="none" strokeWidth="0"/>
                          <g id="e7ZrTe0tQIo4_to" transform="translate(596.527249,239.005663)">
                            <text dx="0" dy="0" fontFamily="&quot;e7ZrTe0tQIo1:::Roboto&quot;" fontSize="28" fontWeight="400" transform="scale(5.023851,10.70437) translate(-77.629691,9.764508)" filter="url(#e7ZrTe0tQIo4-filter)" fill="#71849e" strokeWidth="0">
                              <tspan y="0" fontWeight="400" strokeWidth="0">{'>'}</tspan>
                            </text>
                          </g>
                          <text id="e7ZrTe0tQIo6" dx="0" dy="0" fontFamily="&quot;e7ZrTe0tQIo1:::Concert One&quot;" fontSize="28" fontWeight="700" transform="matrix(4.849631 0 0 5.894193 376.271852 304.188629)" opacity="0" fill="#e60909" stroke="#000">
                            <tspan y="0" fontWeight="700" strokeWidth="0">{'.'}</tspan>
                          </text>
                          <text id="e7ZrTe0tQIo8" dx="0" dy="0" fontFamily="&quot;e7ZrTe0tQIo1:::Concert One&quot;" fontSize="28" fontWeight="700" transform="matrix(4.849631 0 0 5.894193 277.754792 304.5051)" opacity="0" filter="url(#e7ZrTe0tQIo8-filter)" fill="#4e4747" strokeWidth="0">
                            <tspan y="0" fontWeight="700" strokeWidth="0">{'M'}</tspan>
                          </text>
                          <text id="e7ZrTe0tQIo10" dx="0" dy="0" fontFamily="&quot;e7ZrTe0tQIo1:::Concert One&quot;" fontSize="28" fontWeight="700" transform="matrix(4.849631 0 0 5.894193 221.258183 304.337509)" opacity="0" filter="url(#e7ZrTe0tQIo10-filter)" fill="#4e4747" stroke="#000" strokeWidth="0">
                            <tspan y="0" fontWeight="700" strokeWidth="0">{'F'}</tspan>
                          </text>
                          <text dx="0" dy="0" fontFamily="&quot;e7ZrTe0tQIo1:::Roboto&quot;" fontSize="28" fontWeight="400" transform="matrix(5.023851 0 0 10.70437 151.50106 345.570211)" fill="#71849e" strokeWidth="0">
                            <tspan y="0" fontWeight="400" strokeWidth="0">{'<'}</tspan>
                          </text>
                        </g>
                        </svg>

                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Connexion
                  </a>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <a
                    href="#"
                    className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
                  >
                    Connexion
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">La data pour enrichir</span>{' '}
                <span className="block text-indigo-600 xl:inline">votre business</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                Prenez en main le monde de l'Intélligence Artificielle avec une solution IA fait maison pour le traitement, le suivis et la visualisatiion de vos données
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                  >
                    Prenons contact
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="https://portfolio.fredy-mc.fr"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg"
                  >
                    Portfolio
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover lg:mt-20 sm:h-72 md:h-96 lg:h-full lg:w-full"
          src="https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
          alt=""
        />
      </div>
    </div>
  )
}
