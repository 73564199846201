import React from "react";
import { Helmet } from "react-helmet";

function Header() {
    return (
        <Helmet>
            <title>Ideas || MysiteName</title>
            <meta name="keywords" content="HTML,CSS,JavaScript" />
            <meta
            name="description"
            content="Ideas page using react helmet very easy to implement "
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap" rel="stylesheet" /> 
        </Helmet>   
    );
}

export default Header;